import React from "react";
import possibilityImage from "../../assets/inspecticon.png";
import "./possibility.css";

const Possibility = () => (
  <div className="Snag__possibility section__padding" id="possibility">
    <div className="Snag__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="Snag__possibility-content">
      {/* <h4>Request Early Access to Get Started</h4> */}
      <h1 className="gradient__text">
        Our team has years of experience in the construction & quality control
        industry, and we're committed to providing you with highest level of
        service. We also provide a detailed report of our findings, which can
        help you identify any potential issues that need to be addressed.
      </h1>
      {/* <p>Yet bed any for travelling assistance indulgence unpleasing. Not thoughts all exercise blessing. Indulgence way everything joy alteration boisterous the attachment. Party we years to order allow asked of.</p> */}
      {/* <h4>Request Early Access to Get Started</h4> */}
    </div>
  </div>
);

export default Possibility;
