import React from "react";

import {
  Footer,
  Blog,
  Possibility,
  Features,
  WhatSnag,
  Header,
} from "./containers";
import { CTA, Brand, Navbar } from "./components";

import "./App.css";
import Pricing from "./containers/Pricing";
import Customers from "./components/customers";
import { CarouselPage } from "./components/carousel";

const App = () => (
  <div className="App">
    <div className="gradient__bg">
      <Navbar />
      <Header />
    </div>
    {/* <Brand /> */}
    <WhatSnag />
    <Features />
    <Customers />
    <Possibility />
    <CTA />
    <Blog />
    <CarouselPage />
    {/* <Pricing /> */}
    <Footer />
  </div>
);

export default App;
