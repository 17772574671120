import React from 'react';
import PricingTable from './PricingTable';

const Pricing = () => (
  <div className="section__padding">
    <PricingTable />
  </div>
);

export default Pricing;
