import React from "react";
import "../article/article.css";
import EarhPavers from "../../assets/earth.png";
import Alunoz from "../../assets/alunozLogo.png";
import LogoWhite from "../../assets/Logo_white.png";
import WittIndia from "../../assets/WittIndia.png";
import CustomerLogo from "../../assets/customer-logo.jpg";

const Customers = () => (
  <div className="Snag__blog section__padding" id="blog">
    <div className="Snag__blog-heading">
      <h1 className="gradient__text" style={{ textAlign: "center" }}>
        Customers
      </h1>
    </div>
    <div className="Snag__brand section__padding">
      <div style={{ maxWidth: "300px" }}>
        <img src={EarhPavers} style={{ width: "100%" }} alt="blog_image" />
      </div>
      <div style={{ maxWidth: "300px" }}>
        <img src={Alunoz} style={{ width: "100%" }} alt="blog_image" />
      </div>
      <div style={{ maxWidth: "300px" }}>
        <img src={LogoWhite} style={{ width: "100%" }} alt="blog_image" />
      </div>
      <div style={{ maxWidth: "300px" }}>
        <img src={WittIndia} style={{ width: "100%" }} alt="blog_image" />
      </div>
      <div style={{ maxWidth: "300px" }}>
        <img src={CustomerLogo} style={{ width: "100%" }} alt="blog_image" />
      </div>
    </div>
  </div>
);

export default Customers;
