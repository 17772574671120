import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatsnag.css';

const WhatSnag = () => (
  <div className="HomeEval section__margin" id="wSnag">
{/*     <div className="HomeEval-feature">
      <Feature title="Inspection Process" text="We understand that construction is complicated. That's why we focus on helping our clients understand the risks involved and how they can be fixed before they become problems." />
    </div> */}
    <div className="HomeEval-heading">
      <h1 className="gradient__text">Do you want to make sure your new home is safe and sound? We can help assure your decision.</h1>
      
    </div>
    <div className="HomeEval-container">
      <Feature title="Inspect" text="Our  team of experts will look at everything from electrical to structure and mitigate long term risks" />
      <Feature title="Estimate" text="We give you a cost estimate on demand." />
      <Feature title="Rectify" text="A complete inspection report and rectifications will be recommended for you." />
    </div>

  </div>
);

export default WhatSnag;
