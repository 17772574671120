import React, { useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import people from "../../assets/people.png";
import ai from "../../assets/homekey.jpg";
import "./header.css";

const Header = () => {
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post("https://snagindia.com/api/send-email/", { email, number });
    enqueueSnackbar(
      "Thank you for partnering with us. A Snag representative will contact you shortly."
    );
    setEmail("");
    setNumber("");
  };
  return (
    <div className="Snag__header section__padding" id="home">
      <div className="Snag__header-content">
        <h1 className="gradient__text">Quality Inspections made easy.</h1>

        <form className="Snag__header-content__input" onSubmit={handleSubmit}>
          <input
            type="email"
            style={{ background: "#404040" }}
            placeholder="Your Email Address"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
          <input
            type="number"
            style={{ background: "#404040" }}
            placeholder="Your Number (Optional)"
            onChange={(e) => setNumber(e.target.value)}
            value={number}
          />
          <button type="submit">Submit Inquiry</button>
        </form>

        <div className="Snag__header-content__people">
          <img src={people} />
          <p>Be a part of our trusted customers.</p>
        </div>
      </div>

      <div className="Snag__header-image">
        {/* <img src={ai} /> */}
        <h1> "Precision in Every Corner, Assurance in Every Detail"</h1>
      </div>
    </div>
  );
};

export default Header;
