import React from "react";
import "./cta.css";

const CTA = () => (
  <div className="Snag__cta">
    <div className="Snag__cta-content">
      <p>Get a quote today</p>
      <h3>
        At Snag, we pride ourselves on our commitment to customer satisfaction.
        We work closely with our clients to understand their needs and provide
        customized solutions that meet their unique requirements.
      </h3>
    </div>
    {/*     <div className="Snag__cta-btn">
      <button type="button">Get Started</button>
    </div> */}
  </div>
);

export default CTA;
