import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Carousel1 from "../../assets/carousel.jpeg";
import Carousel2 from "../../assets/carousel-2.jpg";
import Carousel3 from "../../assets/carousel-3.jpg";
import Carousel4 from "../../assets/carousel-4.jpg";

export const CarouselPage = () => {
  return (
    <div className="section__padding carousel-section">
      <Carousel showThumbs={false} showArrows={true} infiniteLoop={true}>
        <div>
          <img
            src={Carousel1}
            style={{ height: "60vh", objectFit: "fill" }}
            alt="Slide 1"
          />
        </div>
        <div>
          <img
            src={Carousel2}
            style={{ height: "60vh", objectFit: "fill" }}
            alt="Slide 2"
          />
        </div>
        <div>
          <img
            src={Carousel3}
            style={{ height: "60vh", objectFit: "fill" }}
            alt="Slide 3"
          />
        </div>
        <div>
          <img
            src={Carousel4}
            style={{ height: "60vh", objectFit: "fill" }}
            alt="Slide 4"
          />
        </div>
      </Carousel>
    </div>
  );
};
