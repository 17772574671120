import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Quality Audit',
    text: 'ISO 9001:2015 Certified Auditors',
  },
  {
    title: 'Pre and Post Inspections',
    text: 'Inspections as per customized checklist for various standards and specifications.',
  },
  {
    title: 'Root Cause Analysis',
    text: 'Identification of defects and analysis.',
  },
  {
    title: 'Corrective Action and Improvements',
    text: 'Actions for improvement of standard operating procedures and advanced methodologies. ',
  },
];

const Features = () => (
  <div className="Snag__features section__padding" id="features">
    <div className="Snag__features-heading">
      <h1 className="gradient__text">Try our services now</h1>
    </div>
    <div className="Snag__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
