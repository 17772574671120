import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import './style.css';
// import Paper from '@mui/material/Paper';

function createData(name, price1, price2, price3) {
  return { name, price1, price2, price3 };
}

const rows = [
  createData(
    'Stand Alone Apartments',
    '20 Rs / SFT',
    '20 Rs / SFT',
    '12 Rs / SFT',
  ),
  createData(
    'Gated Communities / Premium Apartments',
    '25 Rs / SFT',
    '20 Rs / SFT',
    '12 Rs / SFT',
  ),
  createData(
    'Independent House / Villa',
    '30 Rs / SFT',
    '20 Rs / SFT',
    '12 Rs / SFT',
  ),
  createData('Commercial Space', '20 Rs / SFT', '20 Rs / SFT', '12 Rs / SFT'),
];

export default function PricingTable() {
  return (
    <TableContainer
      className="Snag__blog-container_article pricing-page"
      sx={{ p: 2 }}
    >
      <div className="pricing_page_heading">
        <h1 className="gradient__text" style={{ textAlign: 'center' }}>
          Pricing
        </h1>
      </div>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableHeading">Areas of Inspection</TableCell>
            <TableCell className="tableHeading">General Inspection <br /> <small style={{ color: 'white', fontWeight: '500', fontSize: 'small' }}>Flooring, Painting, UPVC Doors/Windows, False Ceiling, Grills, Railings, Granite/Marble CP Fitting</small></TableCell>
            <TableCell className="tableHeading">Water Proofing Leakages <br /> <small style={{ color: 'white', fontWeight: '500', fontSize: 'small' }}>Moisture check, Grouts check, Thermal Imaging for root cause</small></TableCell>
            <TableCell className="tableHeading">Electrical or Plumbing Issues <br /> <small style={{ color: 'white', fontWeight: '500', fontSize: 'small' }}>DB installation, Electrical Points, Ceiling light / Plumbing installation check, Damaged pipe lines check</small></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell className="tableCol" component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell className="tableCol" align="center">{row.price1}</TableCell>
              <TableCell className="tableCol" align="center">{row.price2}</TableCell>
              <TableCell className="tableCol" align="center">{row.price3}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
