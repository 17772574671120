import React from "react";
import SnagLogo from "../../logo.svg";
import logo from "../../Asset 2.svg";
import "./footer.css";

const Footer = () => (
  <div className="Snag__footer section__padding">
    {/* <div className="Snag__footer-heading">
      <h1 className="gradient__text">Step In</h1>
    </div> */}

    {/* <div className="Snag__footer-btn">
      <p>Request Early Access</p>
    </div> */}

    <div className="Snag__footer-links">
      <div className="Snag__footer-links_logo">
        <img src={logo} alt="Snag_logo" />
        <p> All Rights Reserved</p>
      </div>
      <div className="Snag__footer-links_div">
        <h3>Links</h3>
        <p>Social Media</p>
      </div>
      <div className="Snag__footer-links_div">
        <h3>Company</h3>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        <p>Contact</p>
      </div>
      <div className="Snag__footer-links_div">
        <h3>Get in touch</h3>
        <p>+91 9000313987</p>
        <p>+91 9000217279</p>
        <p>contact@snagindia.com</p>
      </div>
    </div>

    <div className="Snag__footer-copyright">
      <p>@2023 Snag India Pvt. Ltd.</p>
    </div>
  </div>
);

export default Footer;
